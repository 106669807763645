import React from "react"
import { Link } from "gatsby";
import Page from "../components/Page";

import arrows from '../images/BetfairArrows.svg';
import style from './login.module.scss';

export default ({ location }) => {
    const refer =  location?.state?.refer;
    const href = "/oauthReq" + ((!!refer) ? `?refer=${refer}` : '');
    
    return (
        <Page location={location}>
            <div className={style.modal}>
            
                <h3 className={style.header}>Sign in</h3>
                <h6 className={style.subHeader}>Login with your Betfair account to continue</h6>
        
                <div className={style.btnCont}>
                    <SignInButton href={href} />
                    <p>Don't have an account? <Link className={style.link} to="">Create one</Link></p>
                </div>
            </div>
        </Page>
    );
}

const SignInButton = ({ href }: { href: string }) => {
    return (
    <a className={style.btn} href={href}>
        {/* <a className={style.root} onClick={() => openWindow(href)}> */}
        <img className={style.icon} src={arrows} />
        <span className={style.text}>Sign in with <strong>Betfair</strong></span>
    </a>);
}

const openWindow = (href: string): Window => {
    var w = window.open(href, '_blank', "width=840,height=640");

    w.addEventListener('locationchange', function(){
        console.log('location changed!');
    })
    return w;
}
